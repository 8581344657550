import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import * as IntroplusStyles from "../../styles/introductionplus.module.css";
import Seo from "../../components/seo";

export default function IntroductionPlus({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo description="Il s’agit d’une maintenance spécifique, prescrite par les professionnels consécutivement à l’analyse des polluants CO, HC, NOx, du CO2 et de l’oxygène (O2) . Cette analyse est révélatrice du rendement moteur et prédictive de surcoûts car une voiture en surémission polluante est une voiture qui dysfonctionne et qui peut coûter cher à l’usager." />
      <Container fluid className={IntroplusStyles.Container}>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={IntroplusStyles.Titre1}>
              {t("ecosysteme.easydiag.savoirplus.h.3")}
            </h1>
            <h2 className={IntroplusStyles.Titre2}>
              {t("ecosysteme.easydiag.savoirplus.h.4")}
            </h2>
            <p className={IntroplusStyles.Text}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.p.2">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col xs={12} lg={5} className={IntroplusStyles.ColImage}>
            <StaticImage
              className={IntroplusStyles.GatsbyImage}
              src="../../images/savoirplus/obd.png"
              alt="obd"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="constrained"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={IntroplusStyles.Titre1}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.h.5">
                <sup></sup>
              </Trans>
            </h1>
            <h2 className={IntroplusStyles.Titre2}>
              {t("ecosysteme.easydiag.savoirplus.h.6")}
            </h2>
            <p className={IntroplusStyles.Text}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.p.3">
                a<span>b</span>
                <sub>c</sub>
              </Trans>
            </p>
            <h2 className={IntroplusStyles.Titre2}>
              {t("ecosysteme.easydiag.savoirplus.h.7")}
            </h2>
            <p className={IntroplusStyles.Text}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.p.6">
                a<span>b</span>
                <sub>c</sub>
              </Trans>
            </p>
          </Col>
          <Col xs={12} lg={5}>
            <StaticImage
              className={IntroplusStyles.GatsbyImage}
              src="../../images/savoirplus/Étiquette CT.png"
              alt="étiquette-ct"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="constrained"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

// export page query
export const query = graphql`
  query IntroductionPlus($language: String!) {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
